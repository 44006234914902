import React from "react"
import Link from "gatsby-link"

import ToughTulips from "../images/toughtulips.jpg"

export default () => <div style={{color: 'tomato'}}>
 						<h1> Hello Nami. Happy Mother's Day</h1>
 						<img src={ToughTulips} alt='Tough Tulips fought off day after day of sub-freezing temps.'/>
 						<p>Been at this all day, and this is as far as I got.</p>
 						<Link to="/page-2/">Page 2 </Link>
 						<br />
 						<Link to="/Dir1/page-3/">Page 3 </Link>
 						<br />
 						<Link to="/counter/">Counter </Link>
					</div>


					
